* {
  font-family: brandon-grotesque, sans-serif;
  color: #2f2f2f;
  border: none;
}

body {
  margin: 0;
  background: #f8f9fb;
  overflow: auto;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold !important;
}

h1 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 18px;
  vertical-align: middle;
}

hr {
  height: 1px;
  background-color: #e2e2e2;
  border: none;
}

p {
  margin: 0;
}

.role.attributes {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .role-div {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px !important;
  }
}

input,
textarea {
  border: none;
  background: #f2f2f2;
  border-radius: 5px;
  padding-left: 15px;
  height: 40px;
  width: calc(100% - 15px);
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: #2f2f2f;
}

textarea {
  height: 50%;
  padding: 15px 0 0 15px;
}

ul.branch-group {
  .branch-name {
    .container {
      input[type="checkbox"] {
        display: none;
      }
    }
  }
}

.widthAuto {
  width: auto !important;
  padding-left: 10px;
  padding-right: 10px;
}

.w-full {
  width: 100%;
}

.justify-self-end {
  justify-self: end;
}

.ml-2 {
  margin-left: 0.5rem;
}

.export-container {
  padding-top: 28px;
}

textarea.narrow {
  height: 100px;
}

.ab {
  position: absolute;
}

.reltv {
  position: relative;
}

.moreBranch {
  color: #a9263d;
  font-weight: bold;
}

.type-popup {
  position: relative;
  bottom: -20px;
  left: -30px;
  width: 160px;
  height: 70px;
  background: #ffffff;
  border-radius: 10px;
  z-index: 20;
  padding: 20px;
}

.type-popup:after,
.type-popup:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.type-popup:after {
  border-width: 30px;
  margin-left: -30px;
}

.type-popup:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #ffffff;
  border-width: 15px;
  margin-left: -15px;
}

.type-popup.export-news {
  width: 280px;
  height: auto;
  min-height: 20px;
  left: -65%;
}

.total-count {
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 30px;
  font-size: 12px;
}

.period {
  width: 21%;
}

.image-close {
  position: absolute;
  left: 50px;
  top: 10px;
  width: 20px;
  height: 20px;
  z-index: 30;
}

.image-circle {
  margin-right: 13px;
  .img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .img.big {
    width: 80px;
    height: 80px;
  }
  .img.small {
    width: 20px;
    height: 20px;
  }
  .img.small.overlap {
    margin-right: -16px;
  }
}

.image-circle.big {
  width: 80px;
  height: 80px;
}

.image-square {
  margin-top: 10px;
  .img {
    width: 100%;
  }
  .image-close {
    top: 10px;
    left: 230px;
  }
}

.image-overlay.round {
  border-radius: 50%;
}

.upload-div {
  background: #f2f2f2;
  border-radius: 50%;
  width: 80px;
  height: 60px;
  padding-top: 20px;
  .img {
    width: 40px;
    height: 40px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  z-index: -1;
}

.overlay.active {
  z-index: 10;
  background: black;
}

.image-overlay {
  background: black;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.1;
  z-index: 21;
}

.appcontainer {
  width: 1280px;
  margin: 0 auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.inline {
  display: inline-block;
}

.display-none {
  display: none;
}

.user-logo {
  border-radius: 50%;
  background: #e2e2e2;
  color: #707070;
  font-weight: 300;
  text-align: center;
}

.center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.arrow {
  height: 10px;
  width: 5px;
}

.arrow-left {
  padding-top: 5px;
  margin-right: 10px;
}

.arrow-div {
  width: 5px;
  text-align: right;
  vertical-align: middle;
}

.absolute.arrow-div {
  right: 0;
  top: 25%;
  position: absolute;
}

.with-arrow {
  label {
    height: 10px;
  }
  > div:first-of-type {
    width: calc(100% - 5px);
  }
}

.clickable {
  cursor: pointer;
}

.round-checkbox {
  width: 15px;
  height: 15px;
  background: #f2f2f2;
  border-radius: 50%;
  div {
    margin: 3px 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
  div.active {
    background: #42aee3;
  }
}

.squre-checkbox {
  width: 15px;
  height: 15px;
  background: #f2f2f2;
  img {
    margin-left: 2px;
    margin-top: 3px;
    display: none;
  }
  .active img {
    display: block;
  }
}

.search-field {
  position: relative;
  img {
    position: absolute;
    right: 18px;
    top: 12px;
  }
}

.normal-margin {
  margin: 28px 30px;
}

.normal-margin-heading {
  margin: 28px 30px 10px 30px;
}

.normal-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px #e2e2e2;
}

.normal-box-margin {
  margin-left: 20px;
}

.normal-box-margin-1 {
  margin-left: 160px !important;
}

.normal-box-margin-2 {
  margin-left: 660px;
}

.styles_overlay__CLSq- {
  background: rgba(0, 0, 0, 0.09);
}

.styles_modal__gNwvD {
  box-shadow: none;
  height: 100px;
  width: 290px;
  border-radius: 10px;
  padding: 50px 0 30px 0;
  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
}

.red-white-button {
  position: absolute;
  bottom: 30px;
  left: 28px;
  width: calc(100% - 60px);
  border: 1px solid #a9263d;
  height: 40px;
  border-radius: 5px;
  color: #a9263d;
  font-size: 13px;
  font-weight: bold;
}

.white-blue-button {
  width: 100%;
  margin-bottom: 10px;
  background: #42aee3 0% 0% no-repeat padding-box;
  height: 30px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30px;
  color: #ffffff;
  border-radius: 5px;
  display: block;
}

.blue-white-button {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 30px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30px;
  color: #42aee3;
  border-radius: 5px;
  border: 1px solid #42aee3;
  display: block;
}

.red-white-button.not-ab {
  position: inherit;
  width: 100%;
  margin-top: 20px;
}

.white-red-button,
.white-red-button:hover {
  width: 100%;
  height: 30px;
  background: #a9263d !important;
  border-radius: 5px;
  color: #ffffff !important;
  font-size: 12px;
  font-weight: bold;
  padding: 0 !important;
  text-align: center;
  line-height: 30px;
  display: block;
  margin: auto;
  cursor: pointer;
}

.grey-white-button {
  width: 100%;
  height: 30px;
  background: #ffffff;
  border-radius: 5px;
  color: #b1b1b1;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #b1b1b1;
}

.white-red-button.session {
  height: 50px;
  width: 480px;
  border-radius: 10px;
  font-size: 16px;
}

.error-div {
  border: 1px solid #a9263d;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  color: #a9263d;
  .error-img-div {
    text-align: left;
  }
}

.error-img-div {
  color: #a9263d;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin: 5px 0;
  text-align: left;
  .error-icon {
    margin-right: 10px;
    vertical-align: -20%;
  }
}

.flex {
  display: flex;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.self-center {
  align-self: center;
}

.ml-auto {
  margin-left: auto;
}

.deleteButton {
  background: transparent;
}

.mr-6{
  margin-right: 1.5rem;
}

.addButton {
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 20px;
  background: #a9263d;
  font-weight: bold !important;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.3s;

  span {
    color: #fff;
    &.child-text {
      display: none;
    }
  }

  &:hover {
    width: 80px;
    padding: 0 10px;

    span.child-text {
      font-size: 10px !important;
      display: block;
    }
  }
}

.userModal {
  button {
    height: 40px;
    width: 90px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
  }
  .yesButton {
    background: #a9263d;
    border: 1px solid #a9263d;
    color: #ffffff;
    margin-right: 10px;
  }
  .cancelButton {
    border: 1px solid #b1b1b1;
    color: #b1b1b1;
  }
}

.login-system {
  .error-div {
    width: 400px;
    margin: 0 auto;
    position: absolute;
    top: 10%;
    left: calc(50% - 215px);
  }
  h1 {
    font-weight: 900;
    font-size: 30px;
    text-align: center;
    color: #a9263d;
    margin: 0;
    line-height: 43px;
  }
  h5 {
    margin: 0;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
    line-height: 34px;
  }
  input {
    width: 374px;
    margin-top: 21px;
    height: 60px;
    border-radius: 30px;
    background: #f2f2f2;
    box-shadow: 0px 0px 10px rgba(169, 38, 61, 0);
    padding-left: 25px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }
  input::placeholder {
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }
  button {
    width: 150px;
    height: 50px;
    border-radius: 30px;
    background: #a9263d;
    box-shadow: 0px 0px 10px rgba(169, 38, 61, 0);
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #fff;
    padding: 0;
    margin: 0;
  }
  .button-div {
    margin-top: 19px;
    height: 50px;
    a {
      margin-top: 19px;
    }
    button {
      float: right;
    }
  }
  .red-background {
    h1 {
      color: #fff;
    }
    h5 {
      color: #fff;
    }
  }
  .sign-in-container {
    padding: 30px;
  }
  .sign-in-box {
    width: 400px;
    margin: calc(50vh - 230px) auto;
  }
  .copyright {
    position: absolute;
    bottom: 30px;
    left: 45px;
    font-weight: normal;
    font-size: 12px;
  }
  .left {
    float: left;
    width: 60%;
    height: 100vh;
    position: relative;
  }
  .right {
    background: linear-gradient(#a9263d 0%, #892637 100%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    float: left;
    width: 40%;
    height: 100vh;
    padding-top: calc(50vh - 160px);
    text-align: center;
    button {
      margin-top: 30px;
      width: 200px;
      height: 60px;
    }
  }
  .logo {
    position: absolute;
    left: 30px;
    top: 30px;
  }
}

.Signup,
.ForgetPassword {
  text-align: center;
  .copyright {
    color: #ffffff;
  }
  .button-div {
    margin-top: 30px;
  }
  .postive-vote {
    width: 108px;
    height: 108px;
    margin-top: 36px;
    margin-bottom: 20px;
  }
  p {
    font-weight: normal;
    font-size: 21px;
    text-align: center;
  }
}

.ForgetPassword {
  .sign-in-box {
    width: 400px;
    margin: calc(50vh - 180px) auto;
  }
  .button-div {
    margin-top: 30px;
    text-align: center;
    button {
      float: none;
      margin: auto;
    }
  }
}

.App {
  padding: 30px;
}

.Nav {
  width: 200px;
  float: left;
  a {
    position: relative;
  }
  h1 {
    font-weight: bold;
    font-size: 21px;
    text-align: left;
    margin: 0;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
  }
  .nav-item-parent {
    position: relative;

    .anchor-nav-item-child {
      position: absolute;
      width: 45px;
      height: 45px;
      white-space: nowrap;
      overflow: hidden;
      top: 0;
      left: 155px;
      border-radius: 25px;
      background: #a9263d;
      color: #fff;
      align-items: center;
      display: flex;
      transition: width 0.3s;

      .nav-item-child {
        color: #fff;
        padding-left: 16px;
        display: flex;
        align-items: center;
        z-index: 2;

        span {
          color: #fff;
          font-size: 25px;
          font-weight: bold;
          margin-right: 15px;
        }
        span.child-text {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .anchor-nav-item-child:hover {
      width: 140px;
    }
  }
  .nav-item {
    height: 20px;
    width: 160px;
    margin-bottom: 10px;
    padding: 13px 20px 12px 20px;
    font-weight: 300;
    font-size: 14px;
    text-align: left;
  }
  .active,
  .nav-item:hover {
    background: #ffffff;
    border-radius: 33px;
    font-weight: bold;
  }
  .nav-red-dot {
    position: absolute;
    left: 160px;
    top: 10px;
    z-index: 2;
  }
  .user-logo {
    margin-top: 27px;
    height: 55px;
    width: 70px;
    padding-top: 15px;
    font-size: 30px;
  }
  .user-logo-div {
    position: relative;
    margin-bottom: 8px;
    .nav-red-dot {
      position: absolute;
      left: 60px;
      top: 25px;
    }
  }
}

.nav-red-dot {
  background: #a9263d;
  width: 20px;
  height: 12px;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  padding: 6px 2px;
}

.field-group {
  margin-top: 20px;
  &--mt8 {
    @extend .field-group;
    margin-top: 8px;
  }
  .field {
    margin-top: 10px;
    * {
      font-weight: normal;
      font-size: 13px;
      text-align: left;
      vertical-align: middle;
    }
    &--mt0 {
      @extend .field;
      margin-top: 0px;
    }
    label {
      span {
        font-weight: bold;
      }
    }
    .role-div {
      height: 30px;
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      color: #b1b1b1;
      border-radius: 5px;
      border: 1px solid #b1b1b1;
      cursor: pointer;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 5px;
    }
    .role-div.active {
      background: #42aee3;
      border-color: #42aee3;
      color: #ffffff;
    }
    .role-div:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .with-arrow {
    cursor: pointer;
    padding-right: 20px;
    position: relative;
    label {
      height: 10px;
    }
  }
  .user-logo {
    padding-top: 4px;
    width: 28px;
    height: 24px;
    text-align: center;
    margin-top: 8px;
    font-size: 15px;
    font-weight: 100;
    border: 2px solid #f4f4f4;
    margin-left: -12px;
  }
  .user-logo:first-child {
    margin-left: 0px;
  }
  .more {
    margin-top: 2px;
  }
}

.select-div,
.manage-div {
  overflow: auto;
  position: relative;
  width: 320px;
  height: 700px;
  z-index: 20;
  .item-name {
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #707070;
    height: 40px;
    margin-top: 17px;
    span {
      line-height: 20px;
      vertical-align: middle;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .image-circle {
      height: 40px;
    }
    .user-logo {
      margin-left: 0px;
      margin-right: 5px;
      height: 40px;
      width: 40px;
      line-height: 40px;
      vertical-align: middle;
    }
    .img {
      height: 40px;
      width: 40px;
      vertical-align: middle;
    }
    .image-circle {
      margin-right: 5px;
    }
    .activity.squre-checkbox,
    .activity.round-checkbox {
      margin: 13px 3px;
    }
    .colordot {
      margin-left: 5px;
      height: 5px;
      width: 5px;
      background: #7abb00 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #7abb0073;
      border-radius: 50%;
    }
    .colordot.red {
      background: #a9263d 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #a9263d72;
    }
    .colordot.amber {
      background: #ffd800 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #ffd800;
    }
    .addminus {
      line-height: 40px;
      div {
        margin-right: 5px;
      }
    }
  }
}

.p-0 {
  padding: 0;
}

.mb-4 {
  margin-bottom: 1rem;
}

.line-through {
  text-decoration: line-through;
}

.manage-div {
  z-index: 1;
}

.step-div {
  position: fixed;
  width: 140px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #e2e2e2;
  border-radius: 10px;
  .step-container {
    margin: 10px;
    height: 80px;
    .step-round {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      border: 1px solid #2f2f2f;
      opacity: 0.3;
      margin: auto;
      margin-top: 40px;
    }
    h1 {
      margin-top: 12px;
      text-align: center;
      opacity: 0.3;
      font: Bold 14px/20px;
      letter-spacing: 0;
    }
  }
  .step-container.done {
    .step-round {
      height: 24px;
      width: 24px;
      border: none;
      opacity: 1;
      background: #a9263d;
      text-align: center;
      vertical-align: middle;
    }
    h1 {
      color: #a9263d;
      opacity: 1;
    }
  }
  .step-container.editing {
    .step-round {
      height: 24px;
      width: 24px;
      border: none;
      opacity: 1;
      background: #42aee3;
      text-align: center;
      vertical-align: middle;
    }
    h1 {
      color: #42aee3;
      opacity: 1;
    }
  }
  .step-container:not(:last-of-type) {
    border-bottom: 1px solid #e2e2e2;
  }
}

.Main {
  margin-left: 40px;
  width: 980px;
  float: right;
  .list-div {
    padding-top: 17px;
    padding-bottom: 17px;
    .inline {
      margin-right: 10px;
      vertical-align: middle;
    }
    .user-logo {
      height: 32px;
      width: 40px;
      padding-top: 8px;
      font-size: 18px;
    }
    .name {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      max-width: 260px;
      span {
        font-weight: 300;
        font-size: 12px;
      }
    }
    .arrow-div {
      margin-top: 5px;
    }
    .button-div {
      margin-top: 5px;
      button {
        border: none;
        background: transparent;
        z-index: 5;
        cursor: pointer;
      }
    }
  }
  .manage-list {
    h1 {
      padding: 28px 30px 0 30px;
    }
    .archived {
      margin-left: 10px;
      font-weight: normal;
      font-size: 80%;
    }
    > a {
      display: block;
      padding: 0 30px;
    }
    a:not(:last-of-type) {
      border-bottom: 1px solid #e2e2e2;
    }
    a.active,
    a:hover {
      background: rgba(66, 174, 227, 0.09);
      .user-logo {
        background: #42aee3;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .upsert-one-element-div {
    margin-bottom: 20px;
    position: relative;
    width: 480px;
    min-height: 250px;
    padding-bottom: 80px;
    .upsert-one-element {
      padding: 30px;
      h1 {
        color: #707070;
      }
      label {
        width: auto;
        margin-right: 10px;
        display: block;
        margin-bottom: 3px;
      }
      input {
        max-width: calc(100% - 126px);

        &.narrow {
          margin-top: 5px;
          max-width: calc(100% - 20px);
        }
        &.center {
          padding: 0 8px;
        }
      }
      .colon {
        font-size: 20px;
        line-height: 24px;
      }
      .hour.inline,
      .minute.inline {
        width: 36% !important;
      }
      .am-pm {
        padding-left: 0;
        width: 40px;
        background: #f2f2f2;
        height: 40px;
        margin-left: 20px;
        text-align: center;
        cursor: pointer;
      }
      .hour.inline,
      .colon.inline {
        margin-right: 8px !important;
      }
      .inline.start-time {
        width: 100%;
      }
    }
  }
  .width320px {
    width: 320px;
  }
  .upsert-one-element-div.session {
    .hour.inline,
    .minute.inline {
      width: 26% !important;
    }
    .am-pm {
      padding-left: 0;
      width: 40px;
      background: #f2f2f2;
      height: 40px;
      margin-left: 20px;
      text-align: center;
      cursor: pointer;
    }
    width: 320px;
    min-height: 700px;
  }
  .upsert-one-element-div.goodnewsform {
    z-index: 20;
    textarea {
      margin-left: 5px;
      width: calc(100% - 70px);
      min-height: 100px;
      vertical-align: middle;
      background: #ffffff;
    }
    label {
      min-width: 50px;
      background: #f2f2f2;
      text-align: center;
      height: 42px;
      line-height: 42px;
      font-weight: bold;
    }
    .role.attributes {
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .field {
      border: 1px solid #f4f4f4;
      border-radius: 5px;
    }
    .field-group {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    input {
      width: calc(100% - 66px);
      background: #ffffff;
    }
    .arrow {
      right: 10px;
      top: 17px;
    }
  }
  .upsert-one-element-div.goodnewsform,
  .upsert-one-element-div.goodnews {
    width: 320px;
    min-height: 90px;
    h1 {
      font-size: 14px;
      color: #2f2f2f;
    }
    .user-logo {
      height: 40px;
      width: 50px;
      padding-top: 10px;
      font-size: 21px;
      background: #f4f4f4;
      color: #707070;
      font-weight: 100;
    }
    .user-logo-div {
      position: relative;
      margin-bottom: 8px;
      padding-bottom: 10px;
      border-bottom: 1px solid #b1b1b1;
    }
    .share {
      margin-left: 10px;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0;
      color: #2f2f2f;
      opacity: 1;
      font-weight: 100;
    }
    .photo {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 30px;
      width: 80px;
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 20px;
      h1 {
        margin-top: 8px;
        margin-left: 5px;
      }
    }
    .photo-icon {
      margin-left: 10px;
      margin-top: 4px;
    }
  }
  .role.inline.activity {
    width: calc(100% - 110px);
    .role-div {
      width: 44.5%;
    }
  }
  .activity.upsert-one-element-div {
    min-height: 700px;
  }
  .Breadcrumbs {
    height: 20px;
    margin-bottom: 15px;
    .float-left {
      font-weight: bold;
      font-size: 20px;
      text-align: left;
    }
    .float-right {
      font-weight: 300;
      font-size: 14px;
      text-align: right;
      span {
        font-weight: bold;
      }
    }
  }
  .branch-div {
    width: 140px;
    .branch-name {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 16px;

      div {
        display: inline-block;
      }
      .left {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
      }
    }
    .branch-name.active {
      font-weight: bold;
      color: #a9263d;
      span {
        font-weight: bold;
        color: #a9263d;
      }
    }
  }
  .Users {
    .user-list-div {
      width: 480px;
      height: 700px;
      overflow: auto;
    }
    .select-div {
      width: 320px;
    }
    .edit-user-div {
      position: relative;
      width: 320px;
      min-height: 700px;
      .edit-user {
        .field-group {
          .field {
            label {
              width: 32%;
            }
            input {
              width: 61%;
            }
            .role-div {
              width: 42px;
            }
          }
        }
        .user-logo-div {
          margin: 0 auto;
          width: 120px;
        }
        .user-logo {
          height: 120px;
          width: 120px;
          font-size: 18px;
          line-height: 120px;
          font-size: 40px;
        }
      }
    }
  }
  .user-logo.small {
    padding: 0;
    margin: 0;
    margin-right: 10px;
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    font-size: 10px !important;
  }
  .user-logo.small.overlap {
    margin-right: -5px;
  }
  .add-user-div {
    position: relative;
    width: 480px;
    min-height: 700px;
    margin-left: 0;
    .add-user {
      .field-group {
        .field {
          .role-div {
            width: 78px;
          }
          .role-div:not(:last-of-type) {
            margin-right: 5px;
          }
        }
      }
    }
    .postive-vote {
      padding-top: 20px;
    }
    p {
      padding: 22px 80px;
      font-size: 13px;
    }
    .white-red-button {
      width: 140px;
      margin-bottom: 17px;
    }
    .white-red-button.ab {
      position: absolute;
      right: 30px;
      bottom: 20px;
    }
    a {
      font-size: 12px;
      font-weight: bold;
      color: #a9263d;
      .arrow {
        margin-right: 5px;
      }
    }
  }
  .select-div,
  .manage-div {
    width: 480px;
  }
  .session.select-div {
    width: 320px;
  }
  .Branches,
  .Activities {
    .white-red-button {
      width: 139px;
      position: absolute;
      right: 30px;
      top: 25px;
    }
  }
  .Dashboard {
    margin-bottom: 50px;
    h2 {
      font-size: 21px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 13px;
    }
    p {
      font-size: 12px;
    }
    p.meta,
    span {
      font-size: 10px;
    }
    a {
      color: #a9263d;
    }
    .header {
      position: relative;
      height: 90px;
    }
    .headerTitle {
      float: left;
      max-width: 100px;
      h4 {
        margin: 9px 0 0 0;
      }
    }
    .headerFilter {
      float: right;
      position: relative;
      p {
        display: inline-block;
        color: #2f2f2f;
        margin-left: 25px;
        opacity: 0.8;
        padding-bottom: 5px;
        cursor: pointer;
      }
      p:hover {
        opacity: 1;
        border-bottom: 2px solid #000;
      }
      p.selected {
        opacity: 1;
        font-weight: bold;
        border-bottom: 2px solid #000;
      }
    }
    .customTooltip {
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px #b2b2b2;
      position: relative;
      padding: 11px 0 11px 0;
      width: 110px;
      height: 32px;
      z-index: 1;
      h5 {
        margin: 0;
      }
      .tooltipDate {
        text-align: center;
        margin-bottom: 10px;
      }
      .tooltipPart {
        float: left;
        height: 31px;
        padding: 0 11px 0 10px;
        position: relative;
        border-right: 1px solid #b1b1b1;
        width: 33px;
        span {
          position: relative;
          bottom: 7px;
        }
      }
      .tooltipPart:last-child {
        border: none;
        padding: 0 11px 0 10px;
      }
    }
    .customTooltip.active {
      height: 60px;
    }
    .myCustomTooltip {
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px #b2b2b2;
      position: absolute;
      padding: 11px 0 11px 0;
      width: 110px;
      height: 45px;
      z-index: 1;
      h5 {
        margin: 0;
      }
      .tooltipPart {
        float: left;
        height: 31px;
        padding: 0 11px 0 10px;
        position: relative;
        border-right: 1px solid #b1b1b1;
        width: 33px;
        span {
          position: relative;
          bottom: 7px;
        }
      }
      .tooltipPart:last-child {
        border: none;
      }
      .tooltip-date {
        text-align: center;
        font-size: 12px;
      }
    }
    .myCustomTooltip:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 5px;
      margin-left: -5px;
    }
    .overview {
      margin-bottom: 14.5px;
      position: relative;
      .report-card-date {
        position: absolute;
        top: 45px;
        right: 0;
        font-size: 15px;
      }
      .overviewContainer {
        .overviewBanner {
          max-width: 230px;
          height: 150px;
          width: 100%;
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0px 0px 15px 0px #e2e2e2;
          .overviewInfo {
            position: relative;
            bottom: -30px;
            padding: 15px;
            h2,
            span,
            p {
              color: #fff;
            }
            h2,
            p {
              margin: 0;
            }
            .overviewNumber {
              position: relative;
              h2 {
                div {
                  color: #ffffff;
                }
              }
            }
          }
        }
        .overviewBanner:last-child,
        .overviewBanner:nth-child(4) {
          margin-right: 0;
        }
      }
    }
    .performance {
      padding: 30px;
      background-color: #fff;
      box-shadow: 0px 0px 15px 0px #e2e2e2;
      border-radius: 10px;
      margin-top: 20.5px;
      min-height: 282.5px;
      position: relative;
      .report-card-date {
        position: absolute;
        top: 65px;
        right: 25px;
        font-size: 15px;
      }
      h5,
      span {
        margin: 0;
      }
      .chart {
        height: 170px;
        margin-top: 20.5px;
      }
      .monthChartWrapper {
        display: flex;
        position: relative;
        .monthChart {
          height: 170px;
          width: 100%;
          margin: 0 14px 0 0;
          text-align: center;
        }
        .monthChart:last-child {
          margin: 0;
        }
      }
      .backgroundChart {
        position: absolute;
        height: 170px;
        width: 100%;
      }
      .performanceCharts {
        position: relative;
        .timeBars {
          position: relative;
          display: flex;
          h5 {
            position: relative;
            bottom: -6px;
          }
          span {
            position: relative;
            bottom: 2px;
          }
          .weekBar {
            width: 100%;
            margin: 0 14px 0 0;
            background-color: #f2f2f2;
            border-radius: 5px;
            text-align: center;
          }
          .weekBar.month {
            margin: 0 10px 0 0;
          }
          .weekBar:last-child {
            margin: 0;
          }
        }
      }
    }
    .reportCartWrapper {
      margin-top: 20px;
    }
    .reportCard {
      vertical-align: top;
      max-width: 313px;
      width: 100%;
      height: 600px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #e2e2e2;
      margin: 0 20px 20px 0;
      position: relative;
      overflow: hidden;
      display: inline-block;
      .report-card-date {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 12px;
      }
      select {
        appearance: none;
        border: none;
        border-radius: 22px;
        background-color: #f2f2f2;
        padding: 6px 40px 6px 15px;
        height: 30px;
        margin: 9px 0 0 0;
        font-size: 12px;
        position: relative;
      }
      .reportCardContainer {
        padding: 30px;
        .shortVerticalChartContainer {
          overflow: auto;
          height: 256px;
        }
      }
      .headerFilter {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .headerFilter:after {
        content: "▾";
        position: absolute;
        right: 15px;
        bottom: 4px;
      }
      .feedWrapper {
        overflow: auto;
        height: 366px;
        box-sizing: border-box;
      }
      .feedWrapper::-webkit-scrollbar {
        width: 1px;
      }
      .feedWrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
        border-radius: 2px;
      }
      .feedWrapper::-webkit-scrollbar-thumb {
        border-radius: 2px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
      }
      .feedContainer {
        border-bottom: 1px solid #b1b1b1;
        margin-bottom: 13.5px;
        padding-bottom: 13.5px;
        .authorProfile {
          .authorPicture {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #f4f4f4;
            float: left;
            margin-right: 10px;
          }
          .authorName {
            p {
              font-weight: 700;
              color: #a9263d;
              margin: 0;
            }
            span {
              position: relative;
              bottom: 7px;
            }
          }
        }
        .feedThumb {
          .feedThumbContainer {
            border-radius: 10px;
            height: 120px;
            background-size: cover;
            background-position: center;
          }
        }
        .feedTitle {
          margin-top: 10px;
          p {
            margin: 0;
          }
        }
        .feedExcerpt {
          p {
            margin: 0;
          }
        }
      }
      .feedContainer:last-child {
        border: none;
      }
      .radialChart {
        height: 170px;
        margin-top: 5px;
        position: relative;
        .totalCount {
          margin: 0;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          bottom: calc(170px / 2 - 13px);
          h4,
          p {
            margin: 0;
          }
          p {
            color: #2f2f2f;
            line-height: 0.5;
          }
          .entryWrapper {
            width: 40%;
            margin: 0 auto;
            padding: 0 0 16px 0;
            border-bottom: 1px dashed #b1b1b1;
          }
          .entryWrapper:last-child {
            border: none;
            padding: 8.5px 0 0 0;
          }
        }
      }
      .verticalChart {
        margin-top: 10px;
        position: relative;
        text-align: right;
        .verticalBar {
          position: relative;
          margin-bottom: 13px;
          overflow: hidden;
        }
        .barBackground {
          background-color: #e2e2e2;
          border-radius: 3px;
          width: 100%;
          height: 5px;
          position: absolute;
          z-index: 0;
          bottom: 0;
        }
        .bar {
          height: 5px;
          border-radius: 3px;
          z-index: 1;
          position: relative;
          animation: 1s anim-lightspeed-in ease forwards;
        }
        .barLabel {
          float: left;
          font-size: 12px;
        }
        .barValue {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
    }
    .reportCard.last {
      margin: 0;
    }
    .switch {
      position: absolute;
      display: inline-block;
      width: 25px;
      height: 15px;
      top: 15px;
      right: 15px;
      z-index: 1;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border: 1px solid #fff;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 9px;
      width: 9px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
    /* Rounded sliders */
    .slider.round {
      border-radius: 9px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
  .Surveys {
    @extend .Users;
    .survey-info {
      .name {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        span {
          font-weight: 300;
          font-size: 12px;
        }
      }
      hr {
        background: transparent;
        border-top: 1px solid #e2e2e2;
        border-left: none;
        border-right: none;
        border-bottom: none;
      }
      .surveyLine {
        margin-top: 15px;
        .surveyLineTitle {
          font-size: 12px;
          font-weight: bold;
        }
        .surveyLineValue {
          font-size: 12px;
          margin-top: 5px;
          min-height: 30px;
          .imageContainer {
            position: relative;
            margin-right: 5px;
            text-align: center;
            width: 30px;
            height: 30px;
            float: left;
            img {
              height: 18px;
            }
            img[alt="0"] {
              height: 10px;
            }
          }
        }
      }
      .surveyLine:first-child {
        margin-top: 0;
        margin-bottom: 18px;
      }
    }
  }
  .AddSurvey {
    @extend .Users;
    .stepContainer {
      width: 420px;
      min-height: 600px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #e2e2e2;
      border-radius: 10px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .navigation {
        height: 30px;
        margin-top: auto;
        align-self: flex-start;
        width: 100%;
        .navArrow {
          font-size: 14px;
          color: #a9263d;
          cursor: pointer;
        }
        .navArrow:hover {
          color: #731a1a;
        }
        .back {
          float: left;
        }
        .next {
          float: right;
        }
      }
      .stepTitle {
        text-align: center;
        color: #2f2f2f;
        font-size: 21px;
        font-weight: bold;
      }
      .stepTitle.success {
        font-size: 20px;
        color: #2f2f2f;
        font-weight: normal;
        margin-top: 21px;
      }
      .logAnother {
        border-radius: 10px;
        width: 300px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        margin-top: 18.5px;
        cursor: pointer;
      }
      .returnLink {
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
        button {
          color: #a9263d;
          font-weight: bold;
          appearance: none;
          border: none;
          cursor: pointer;
        }
        button:hover {
          opacity: 0.7;
        }
      }
      .stepName {
        text-align: center;
        font-size: 14px;
        color: #2f2f2f;
      }
      .branchList {
        margin-top: 17px;
        height: 100%;
        margin-bottom: 0;
        overflow: hidden;
        .groupContainer {
          overflow-y: auto;
          overflow-x: hidden;
          height: 420px;
        }
        .groupContainer.surveyField {
          height: 100%;
          padding-top: 10px;
          text-align: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-content: flex-start;
        }
        .groupContainer.surveyField.column {
          flex-direction: column;
          align-content: center;
          justify-content: flex-start;

          .selectSurvey {
            width: 140px;
            height: 140px;
            padding: 30px 20px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
          }
        }
        .form-control {
          margin-bottom: 20px;
        }
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .branch-group {
          margin: 0px;
        }
        .checkmark {
          position: absolute;
          top: 0;
          right: 0;
          height: 15px;
          width: 15px;
          border-radius: 5px;
          background-color: #eee;
        }
        .checkmark:after {
          right: 3px;
          top: 4px;
          width: 7px;
          height: 3px;
          border: solid white;
          border-width: 0 0 2px 2px;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          content: "";
          position: absolute;
          display: none;
        }
        .selectSurvey {
          display: block;
          text-align: center;
          position: relative;
          box-sizing: border-box;
          padding: 20px;
          margin-left: auto;
          margin-right: auto;
          cursor: pointer;
          .checkmark {
            background-color: transparent;
            border-radius: 50%;
            top: -5.5px;
            right: -5.5px;
            z-index: 1;
          }
          .selectName {
            text-transform: capitalize;
            font-size: 14px;
          }
          .selectName.active {
            color: #2196f3;
          }
          .borderCheck {
            width: 100%;
            height: 100%;
            border: 1px solid #e2e2e2;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;
            background-color: #fff;
            transition: 0.2 all ease;
            box-sizing: border-box;
          }
        }
        .surveyField.displayBlock {
          display: block;
        }
        .attribute_type.selectSurvey.fill-4 {
          width: 140px;
          height: 140px;
          padding: 30px 20px;
          margin: 10px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
        }
        .selectSurvey.fill-3 {
          width: 140px;
          height: 140px;
          padding: 30px 20px;
          margin-bottom: 20px;
          margin: 10px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
        }
        .selectSurvey.fill-2 {
          width: 140px;
          height: 140px;
          padding: 30px 20px;
          margin-bottom: 20px;
        }
        .selectSurvey.fill-4 {
          width: 155px;
          height: 80px;
          padding: 10px;
          margin-bottom: 10px;
        }
        .selectSurvey.fill-5 {
          width: 155px;
          height: 60px;
          margin-bottom: 10px;
        }
        .selectSurvey.satisfaction {
          width: 60px;
          height: 60px;
          padding: 13px;
          .selectName {
            display: none;
          }
        }
        .selectSurvey:hover input ~ .borderCheck,
        .selectSurvey input:checked ~ .borderCheck {
          border: 2px solid #42aee3;
          background-color: transparent;
          opacity: 1;
        }
        .selectSurvey input:checked ~ .checkmark {
          background-color: #2196f3;
        }
        /* Show the checkmark when checked */
        .selectSurvey input:checked ~ .checkmark:after {
          display: block;
        }
        .selectSurvey:last-child {
          margin-bottom: 0;
        }
        ul {
          list-style: none;
          padding-left: 0;
          .container {
            display: block;
            position: relative;
            padding-right: 35px;
            margin-bottom: 17px;
            cursor: pointer;
            font-size: 12px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .activityIcon {
              width: 18px;
              margin-right: 16px;
              position: relative;
              bottom: -4px;
            }
          }
          .container:hover input ~ .checkmark {
            background-color: #ccc;
          }
          .container input:checked ~ .checkmark {
            background-color: #2196f3;
          }
          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }
        }
      }
    }
  }
}

.loaderContainer {
  width: 100%;
  position: relative;
  .loaderRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .loaderDiv {
      flex: 1 0 15%;
      margin: 5px;
    }
    .three-fourth {
      flex-grow: 20;
    }
  }
  .circleLoad {
    @extend .animated-background;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .textLoad {
    @extend .animated-background;
    height: 7px;
    margin-bottom: 6px;
  }
  .half {
    width: 50%;
  }
  .quarter {
    width: 25%;
  }
  .big {
    height: 12px;
  }
  .bigMargin {
    margin-bottom: 15px;
  }
  .hide {
    display: none;
  }
  .radialCircle {
    @extend .animated-background;
    border-radius: 50%;
    width: 154px;
    height: 154px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .radialCircle:before {
    content: "";
    background: #fff;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    display: block;
    top: 17px;
    position: relative;
  }
  .resultBar {
    width: 100%;
    display: flex;
    margin-bottom: 20.5px;
    .barPlaceholder {
      @extend .animated-background;
      height: 42px;
      width: 100%;
      border-radius: 5px;
      margin-left: 10px;
    }
    .barPlaceholder:first-child {
      margin-left: 0;
    }
  }
  .resultChart {
    width: 100%;
    display: flex;
    .chartPlaceholder {
      @extend .animated-background;
      height: 150px;
      width: 100%;
      border-radius: 5px;
      margin-left: 13px;
    }
    .chartPlaceholder:first-child {
      margin-left: 0;
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f2f2;
  background: linear-gradient(to right, #eaeaea 8%, #f2f2f2 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

.App {
  ::-webkit-scrollbar {
    width: 1px;
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    }
  }
}

.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name {
  text-align: center !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input,
.react-datepicker-ignore-onclickoutside {
  width: 0px;
  height: 0px;
  padding: 0;
}

.session-branch-datepicker {
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input,
  .react-datepicker-ignore-onclickoutside {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
  .react-datepicker__input-container input {
    padding-left: 10px;
    width: calc(100% - 10px);
  }
  .react-datepicker-popper {
    z-index: 10;
  }
  .image-close {
    left: 110px;
    top: 10px;
    position: absolute;
  }
  .field {
    select {
      width: 100%;
      background-color: #f2f2f2;
      height: 40px;
      margin-bottom: 10px;
    }
  }
}

.Workplans,
.AddWorkplan {
  .field {
    margin-top: 5px;
    input {
      height: 30px;
      text-align: center;
      padding-left: 0;
      width: 100%;
      max-width: calc(100% - 85px) !important;
    }
    input.occurrence {
      width: 100%;
      max-width: calc(100% - 168px) !important;
    }
  }
  .user-logo {
    margin: 0;
    margin-left: 10px;
  }
  .arrow.user {
    margin-left: 10px;
  }
  .more {
    font-size: 13px;
  }
  .age {
    width: 14.5%;
  }
  .age:not(:last-of-type) {
    margin-right: 3px !important;
  }
  .gender {
    width: 15.5%;
  }
  .assign {
    margin-left: 8px;
  }
}

.select-div {
  .normal-margin {
    height: 480px;
    overflow-x: auto;
    overflow-x: hidden;
    margin-top: 0px;
  }
}

img.feedThumbContainer {
  width: 100%;
}

.GoodNews {
  .manage-div {
    height: calc(100vh - 170px);
  }
  .manage-list {
    background: #f8f9fb;
  }
  .list-div {
    background: #ffffff;
    margin-bottom: 20px;
    padding: 20px;
  }
  .feedContent,
  .feedTitle {
    margin-top: 8px;
  }
  p {
    font-size: 12px;
  }
}

.dot-black {
  margin-top: 8px;
}

.edit-box {
  height: 57px;
  width: 77px;
  z-index: 20;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #2f2f2f;
  border-radius: 5px;
  border: 0px;
  opacity: 1;
  right: 30px;
  top: 40px;
  padding: 20px;
  p {
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 1280px) {
  .login-system {
    width: 1280px;
  }
  .type-popup.type-export {
    left: 970px;
  }
}

.export-link {
  right: 30px;
  top: 25px;
  text-align: right;
  font-weight: Bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: #a9263d;
  opacity: 1;
}

.type-popup.type-export {
  left: calc(50vw + 70px);
  position: absolute;
  top: 130px;
  width: 300px;
  height: 90px;
  background: #ffffff;
  border-radius: 10px;
  z-index: 20;
  padding: 20px;
  .field-group {
    margin-top: 0;
  }
  label {
    width: 100px;
  }
  select {
    width: calc(100% - 100px);
    background: #f2f2f2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 30px;
  }
  .yesno {
    width: calc(100% - 100px);
    div {
      width: 46%;
      text-align: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #b1b1b1;
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
    }
    div:first-of-type {
      margin-right: 10px;
    }
    div.active {
      background: #42aee3 0% 0% no-repeat padding-box;
      color: #ffffff;
    }
  }
  .white-red-button {
    margin-right: 10px;
  }
  button {
    margin-top: 10px;
    width: 48%;
    display: inline-block;
  }
}

.type-popup.type-export.active {
  height: 130px;
}

.type-popup.type-export.branch-select {
  height: 170px;
}

.type-popup.type-export.active.branch-select {
  height: 210px;
}

.type-popup.type-export.custom-date {
  height: 420px;
}

.type-popup.type-export.active.custom-date {
  height: 470px;
}

.type-popup.type-export.custom-date.branch-select {
  height: 450px;
}

.type-popup.type-export.active.custom-date.branch-select {
  height: 540px;
}

@media only screen and (max-width: 1280px) {
  .login-system {
    width: 1280px;
  }
  .type-popup.type-export {
    left: 740px;
  }
}

.loading-img {
  position: fixed;
  left: 50vw;
  top: 40vh;
  z-index: 30;
}

.GoodNews .feedThumbContainer {
  border-radius: 10px;
  min-height: 250px;
  background: #707070;
}

.lastActivitySession {
  font-size: 18px !important;
  font-weight: 300;
}

.overview-item,
.overview-arrow {
  font-size: 16px !important;
}

.overview-stats {
  margin-right: 10px;
}

.activity-search {
  width: 480px;
  margin-bottom: 20px;
}

.location-selector {
  font-weight: bold;
  margin-bottom: 18px;
}

.Dashboard,
.stepContainer,
.type-export {
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker-wrapper input {
    height: 40px;
    margin-bottom: 10px;
  }
  .react-datepicker-wrapper {
    width: 46%;
    margin-left: 10px;
    margin-top: 10px;
  }
  .react-datepicker__input-container,
  .react-datepicker-wrapper input {
    width: 100%;
  }
  .react-datepicker-wrapper input {
    text-align: center;
  }
  .react-datepicker__day--in-range {
    background: #64b9f8 0% 0% no-repeat padding-box;
    color: black;
    opacity: 0.8;
  }
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: #216ba5 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0.23rem;
  }
  .datepicker-div {
    width: 255px;
    position: absolute;
    z-index: 20;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    label,
    input {
      color: #2f2f2f;
      opacity: 0.8;
      font-size: 11px;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .apply-button {
      background: #216ba5 0% 0% no-repeat padding-box;
      color: #ffffff;
      height: 32px;
    }
    .input-div {
      padding: 10px 15px;
      .apply-button {
        width: 45px;
      }
    }
    .date-picker {
      width: 75px;
      height: 30px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      margin-right: 5px;
      padding-left: 5px;
    }
    .date-picker.active {
      border: 2px solid #216ba5;
    }
    .custom-datepicker {
      display: none;
    }
    .custom-datepicker.active {
      display: block;
    }
    .react-datepicker {
      border: none;
    }
    .calendar-svg {
      height: 15px;
      width: 13px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      opacity: 0.6;
    }
  }
  .datepicker-div.position-overview {
    right: 0;
    top: 50px;
  }
  .datepicker-div.position-performance {
    right: 30px;
    top: 80px;
  }
  .datepicker-div.position-reportcard {
    right: 20px;
    top: 90px;
  }
  .datepicker-div:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    right: 20%;
    width: 20px;
    height: 20px;
    background: #f0f0f0;
    border-right: 1px solid #b1b1b1;
    border-top: 1px solid #b1b1b1;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    z-index: -1;
  }
  .react-datepicker-popper {
    z-index: 10;
  }
  .select-date-div {
    background: #a9263d 0% 0% no-repeat padding-box;
    color: #ffffff;
    line-height: 35px;
    text-align: center;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    height: 35px;
    position: absolute;
    cursor: pointer;
  }
  .select-date-div.position-overview {
    right: 0;
    top: 40px;
  }
  .select-date-div.position-performance {
    right: 30px;
    top: 70px;
  }
  .select-date-div.position-reportcard {
    right: 20px;
    bottom: 20px;
  }
}

.stepContainer {
  .datepicker-div {
    position: relative;
    width: 100%;
    background: none;
    border: none;

    &::after {
      display: none;
    }

    .date-picker {
      width: 100%;
    }

    .input-div {
      padding-left: 0;
      padding-right: 0;
    }

    .custom-datepicker {
      text-align: center;
      margin-top: 20px;
    }
  }
}

.type-export {
  .datepicker-div {
    position: inherit;
    width: 100%;
    border: none;
    background: inherit;
    .date-picker {
      width: 136px;
    }
    .input-div {
      padding: 0;
    }
    .react-datepicker {
      border: 1px solid #aeaeae;
    }
    .custom-datepicker {
      text-align: center;
      margin-top: 10px;
    }
  }
  .datepicker-div:after {
    width: 0;
    height: 0;
  }
  .react-datepicker__navigation {
    width: 10px !important;
  }
}

.reportCard.placeholder-css {
  background: #fff;
  position: relative;
  height: 486px;
}

.single-branch {
  max-height: 600px;
  overflow-y: scroll;
}

.print-button {
  position: absolute;
  width: 100px;
  left: 0;
  top: 40px;
}

.overview .white-red-button:hover {
  width: 100px;
}
